<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="width:100%;padding:100px 0 10px;"
  >
    <nsvg />
    <h5
      class="text-secondary"
      style="padding:60px 0"
    >
      很抱歉，您访问的页面不存在
    </h5>
    <router-link to="/store">
      <btn class="btn-outline-primary">
        回到首页
      </btn>
    </router-link>
  </div>
</template>

<script>
import nsvg from './_404Svg.vue'

export default {
  name: 'NotFound',
  // eslint-disable-next-line vue/no-unused-components
  components: { nsvg },
}
</script>
